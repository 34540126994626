import React from "react";
import imgBanner from "../../assets/image/inner-page/png/employee-banner.png";

const Content9 = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        {/* Section title */}
        <div className="row align-items-xl-center justify-content-between">
          <div
            className="col-xl-7 col-lg-6 col-md-10"
            data-aos="fade-right"
            data-aos-delay={500}
            data-aos-once="true"
          >
            <h3 className="font-size-10 mb-0 letter-spacing-n83">
              La comunicación con tu empleado/a es importante
            </h3>
          </div>
          <div
            className="col-xl-5 col-lg-6 col-md-8"
            data-aos="fade-left"
            data-aos-delay={500}
            data-aos-once="true"
          >
            <p className="font-size-7 mb-0 pr-xl-2 pr-lg-5 pr-md-10 pr-sm-20 mt-7 mt-lg-0">
              Por eso toda la información que necesita saber la reunimos en el
              mismo lugar para que se mantenga actualizado.
            </p>
          </div>
        </div>
        {/* End Section title */}
        {/* Banner */}
        <div className="row">
          <div className="col-12">
            <div
              className="pt-10 pt-lg-17 rounded-10"
              data-aos="fade-up"
              data-aos-delay={700}
              data-aos-once="true"
            >
              <img className="w-100" src={imgBanner} alt="" />
            </div>
          </div>
        </div>
        {/* End Banner */}
        {/* Services */}
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={500}
              data-aos-once="true"
            >
              <div className="square-60 bg-blue-3 shadow-dodger-blue-3 rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-sun" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Hoy</h4>
                <p className="font-size-5 mb-0">
                  Turno y tareas que tiene que realizar en el día junto a los
                  fichajes realizados.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={700}
              data-aos-once="true"
            >
              <div className="square-60 bg-red shadow-red rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-calendar" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Mes</h4>
                <p className="font-size-5 mb-0">
                  Situación de todos los aspectos claves en la empresa durante
                  el mes.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={900}
              data-aos-once="true"
            >
              <div className="square-60 bg-buttercup shadow-buttercup rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-globe-europe" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Año</h4>
                <p className="font-size-5 mb-0">
                  Situación durante lo que lleva de año en la empresa.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={1100}
              data-aos-once="true"
            >
              <div className="square-60 bg-dark-green shadow-dark-green rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-chart-bar" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Horas</h4>
                <p className="font-size-5 mb-0">
                  Gráfica de horas con lo planificado y ha realizado.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={1300}
              data-aos-once="true"
            >
              <div className="square-60 bg-light-orange shadow-light-orange rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-clock" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Cómputo de horas</h4>
                <p className="font-size-5 mb-0">
                  Balance de horas realizadas sobre la planificación.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={1500}
              data-aos-once="true"
            >
              <div className="square-60 bg-purple-heart shadow-purple-heart rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-hand-holding" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Solicitudes</h4>
                <p className="font-size-5 mb-0">
                  Número de solicitudes pendientes que ha realizado.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={1700}
              data-aos-once="true"
            >
              <div className="square-60 bg-bali-gray shadow-bali-gray rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-clock" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Horas a pagar</h4>
                <p className="font-size-5 mb-0">
                  Horas a pagar reconocidas por la empresa en el mes.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={1900}
              data-aos-once="true"
            >
              <div className="square-60 bg-neon-green shadow-neon-green rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-retweet" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Permutas de turno</h4>
                <p className="font-size-5 mb-0">
                  Cambios de jornadas entre compañeros/as en el mes.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={2100}
              data-aos-once="true"
            >
              <div className="square-60 bg-golden-yellow shadow-golden-yellow rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-retweet" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Permutas de vacaciones</h4>
                <p className="font-size-5 mb-0">
                  Cambios de vacaciones entre compañeros/as en el mes.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={2300}
              data-aos-once="true"
            >
              <div className="square-60 bg-dodger-blue-2 shadow-dodger-blue-2 rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-clock" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Tiempos excedidos</h4>
                <p className="font-size-5 mb-0">
                  Horas realizadas fuera de la jornada que han sido reconocidas
                  por la empresa en el mes.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={2500}
              data-aos-once="true"
            >
              <div className="square-60 bg-purple-heart shadow-purple-heart rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-clock" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Ausencias</h4>
                <p className="font-size-5 mb-0">
                  Veces que se ha ausentado durante la jornada laboral en el
                  mes.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={2700}
              data-aos-once="true"
            >
              <div className="square-60 bg-buttercup shadow-buttercup rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-umbrella-beach" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Vacaciones</h4>
                <p className="font-size-5 mb-0">
                  Días de vacaciones asignadas en el mes.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={2900}
              data-aos-once="true"
            >
              <div className="square-60 bg-golden-yellow shadow-golden-yellow rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-clock" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Jornadas excedidas</h4>
                <p className="font-size-5 mb-0">
                  Horas realizadas fuera de la jornada y no han sido reconocidas
                  por la empresa en el mes.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={3000}
              data-aos-once="true"
            >
              <div className="square-60 bg-bali-gray shadow-bali-gray rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-clock" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Tiempos debidos</h4>
                <p className="font-size-5 mb-0">
                  Horas que se deben a la empresa.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={3100}
              data-aos-once="true"
            >
              <div className="square-60 bg-sunset shadow-sunset rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-newspaper" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Noticias</h4>
                <p className="font-size-5 mb-0">
                  Comparte con tus empleados/as las últimas novedades de la
                  empresa.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* End Services */}
      </div>
    </div>
  );
};

export default Content9;
